:root {
    --gap-size: .25em;
    --grid-size: 10;
    --grid-columns: var(--grid-size);
    --grid-rows: var(--grid-size);
    --gap-num: calc(var(--grid-size) - 1);
}

#btn-1, #btn-4 {
    grid-column: 1 / span 3;
}

.hovering {
    border: 5px solid red;
}

button[disabled] {
    background-color: lightgray;
}